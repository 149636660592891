import './style.scss'
import Home from '../Home/index'
import {useEffect, useState} from "react";
import ImgMode from '../ImgMode/index'
import MobileImgMode from '../ImgMode/mobile'
import TeamPC from '../Team/PC'
import TeamM from '../Team/Mobile'
import Footer from "../Footer/index";
import DoWhat from "../DoWhat/index";
import AiMusic from "../AiMusic/index";
import AiVideo from "../AiVideo/index";
import AiPhoto from "../AiPhoto/index";
import NavBar from "../NavBar";
import imgMode from "./imgMode";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import animation from "./animation";
import ImgDiffuse from "../ImgDiffuse";

function Index() {
    const [AniShow, setAniShow] = useState(false)
    const [teamAni, setTeamAni] = useState(false)
    const [photoAni, setPhotoAni] = useState(false)
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.fromTo(".team .photowrap", {
            onComplete: () => {
                setTeamAni(true)
            },
            y: 200,
        }, {
            y: 0,
            duration: 1,
            scrollTrigger: {
                trigger: ".team .photowrap",
                start: "top bottom",
                toggleActions: "play none none none"
            }
        });

        const tl = gsap.timeline({
            onComplete: () => {
                setAniShow(true)
            },
            onStart: () => {
                setPhotoAni(true)
            },
            scrollTrigger: {
                trigger: ".aiphoto .swiper-wrapper-s", // 共同的触发容器
                start: "top 80%", // 时间轴触发的位置
                toggleActions: "play none none none"
            }
        });
        tl.fromTo(
            ".aiphoto .swiper-wrapper-s .center",
            {y: 400, opacity: 0},
            {
                y: 0,
                duration: 0.8,
                opacity: 1
            },
            0
        );
        tl.fromTo(
            ".aiphoto .swiper-wrapper-s .other",
            {y: 400, opacity: 0},
            {
                y: 0,
                duration: 0.8,
                opacity: 1
            },
            0.2
        );
        imgMode()
        animation()
    }, []);
    return (
        <div style={{background: 'black'}}>
            <NavBar></NavBar>
            <Home></Home>
            {window.innerWidth > window.innerHeight ? (
                <div>
                    <ImgMode></ImgMode>
                    <ImgDiffuse></ImgDiffuse>
                </div>
            ) : (
                <MobileImgMode></MobileImgMode>
            )}
            <AiPhoto AniShow={AniShow} photoAni={photoAni}></AiPhoto>
            <AiVideo></AiVideo>
            <AiMusic></AiMusic>
            <DoWhat></DoWhat>
            {window.innerWidth > window.innerHeight ? (
                <TeamPC teamAni={teamAni}></TeamPC>
            ) : (
                <TeamM></TeamM>
            )}
            <Footer mode={'pc'}></Footer>
        </div>
    )
}

export default Index