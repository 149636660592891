import './style.scss'
import {useEffect} from "react";


function AiVideo({current}) {
    const videoSources = [
        window.ASSETS.aiVideo_1,
        window.ASSETS.aiVideo_2,
        window.ASSETS.aiVideo_3
    ];
    useEffect(() => {
        document.querySelectorAll(".video-list video").forEach(video => {
            video.play()
        })
    }, [])
    return (
        <div className="aivideo">
            <div className="text">
                <p className="aniText">
                    Artifcial Intelligence Video
                </p>
                <h1 className="aniText">
                    AI视频
                </h1>
                <p className="aniText">
                    在AI视频领域，我们具备将文本转化为视频内容的能力，包括文生视频、图生视频、涂鸦生视频和视频换脸等
                </p>
            </div>
            <div className="videos">
                <div className="top">
                    <div className="top-video">
                        <video playsInline src={window.ASSETS.aiVideo_1} loop autoPlay muted>

                        </video>
                    </div>
                </div>
                <div className="center">
                    <div className="center-left-video">
                        <video playsInline src={window.ASSETS.aiVideo_2} loop autoPlay muted>

                        </video>
                    </div>
                    <div className="center-right-video">
                        <video playsInline src={window.ASSETS.aiVideo_3} loop autoPlay muted>

                        </video>
                    </div>
                </div>
                <div className="bottom">
                    <div className="bottom-left-video">
                        <video playsInline src={window.ASSETS.aiVideo_4} loop autoPlay muted>

                        </video>
                    </div>
                    <div className="bottom-right-video">
                        <video playsInline src={window.ASSETS.aiVideo_5} loop autoPlay muted>

                        </video>
                    </div>
                </div>
            </div>
            <div className="video-list">
                {videoSources.map((src, index) => (
                    <video playsInline key={index} src={src} loop muted/>
                ))}
            </div>
        </div>
    )
}

export default AiVideo