import './style.scss'
import {useState, useEffect} from 'react'

const member = [...window.ASSETS.teamMember, ...window.ASSETS.teamMember, ...window.ASSETS.teamMember]

function PC({teamAni}) {
    const [speed, setSpeed] = useState(0.2)
    const [photoLeft, setPhotoLeft] = useState(78)
    const increase = () => {
        setSpeed(2);
        setTimeout(() => {
            setSpeed(0.2);
        }, 500)
    }
    const reduce = () => {
        if (photoLeft >= 78) {
            return
        }
        setSpeed(-2);
        setTimeout(() => {
            setSpeed(0.2);
        }, 500)
    }
    useEffect(() => {
        if (teamAni === true) {
            document.querySelector(".team .photo").style.transition = "none"
            const intervalId = setInterval(() => {
                setPhotoLeft(prev => {
                    if (Math.floor(prev) <= -1872) {
                        prev = 0
                    }
                    return prev - speed
                });
            }, 1);
            return () => clearInterval(intervalId);
        }
    }, [speed, teamAni]);
    return (
        <div className="team">
            <div className="content">
                <div className="aniText">
                    <h1>我们的团队</h1>
                    <p>汇聚了一批经验丰富的软件工程师、数据科学家、AI专家和创意设计师</p>
                </div>
                <div className="photowrap">
                    <div className="leftMask"></div>
                    <div className="photo" style={{transform: `translateX(${photoLeft}px)`}}>
                        {member.map((item, index) => {
                            return (
                                <div key={index} className={`member member${index}`}>
                                    <img src={item.img} alt=""/>
                                    <p>
                                        {item.job}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="rightMask"></div>
                </div>
                <div className="speed">
                    <div className="left" onClick={(e) => {
                        reduce()
                    }}>
                        <img alt="" src={window.ASSETS.team_left}/>
                    </div>
                    <div className="right" onClick={(e) => {
                        increase()
                    }}>
                        <img alt="" src={window.ASSETS.team_right}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PC