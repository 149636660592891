import './style.scss'

function Mobile() {
    return (
        <div className="m-imgmode">
            <div className="text">
                <h1 className="aniText">
                    图片生成
                </h1>
                <p className="intro aniText">
                    像聊天一样生成图片，让创作变得更简单一次Promt多种风格，无限激发灵感，让想象变具象
                </p>
            </div>
            <div className="ani">
                <div>
                    <img className="flower_left" src={window.ASSETS.imgMode_leftFlower} alt=""/>
                    <img className="flower_right" src={window.ASSETS.imgMode_rightFlower} alt=""/>
                </div>
                <div className="tabletWrap">
                    <img className="tablet" src={window.ASSETS.imgMode_tablet} alt=""/>
                </div>
            </div>
        </div>
    )
}

export default Mobile