import './style.scss'
import {useEffect, useRef} from "react";
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.min.css';


function AiPhoto({photoAni, AniShow}) {
    useEffect(() => {
        const swiper = new Swiper('#certify .swiper-container', {
            watchSlidesProgress: true,
            slidesPerView: 'auto',
            centeredSlides: true,
            loop: true,
            loopedSlides: 3,
            autoplay: {
                delay: 2000, // 设置延时为3秒
                disableOnInteraction: true, // 用户操作后禁用自动播放
                enabled: false // 禁用自动播放功能
            },
            on: {
                progress: function (progress) {
                    for (let i = 0; i < this.slides.length; i++) {
                        let slide = this.slides.eq(i);
                        let slideProgress = this.slides[i].progress;
                        let modify = 1;
                        if (Math.abs(slideProgress) > 1) {
                            modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                        }
                        let translate = slideProgress * modify * 150 + 'px';
                        let scale = 1 - Math.abs(slideProgress) / 5;
                        let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                        slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                        slide.css('zIndex', zIndex);
                        slide.css('opacity', 1);
                        if (Math.abs(slideProgress) > 3) {
                            slide.css('opacity', 0);
                        }
                    }
                },
                setTransition: function (swiper, transition) {
                    for (let i = 0; i < this.slides.length; i++) {
                        let slide = this.slides.eq(i)
                        slide.transition(transition);
                    }
                }
            }
        })
        if (photoAni === true) {
            swiper.autoplay.start();
        }
    }, [photoAni])
    useEffect(() => {
        new Swiper('.m-imgs', {
            watchSlidesProgress: false,
            slidesPerView: 1,
            centeredSlides: true,
            loop: true,
            loopedSlides: 1,
            spaceBetween: 100,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                enabled: true
            }
        });
    }, [])
    const swiper1 = useRef(null)
    return (
        <div className="aiphoto">
            <div className="text">
                <p className="aniText">
                    Artificial Intelligence Photo
                </p>
                <h1 className="aniText">
                    AI写真
                </h1>
                <p className="aniText">
                    上传一张照片即可生成AI写真，或将真人转化为卡通或动漫风格，秒级生成
                    广泛应用于AIGC互动营销场景，让品牌方获客更容易
                </p>
            </div>
            <div id="certify">
                {AniShow === false && (
                    <div className="swiper-wrapper-s">
                        <div className="other swiper-slide"><img src={window.ASSETS.aiPhoto_8} alt=""/></div>
                        <div className="center swiper-slide"><img src={window.ASSETS.aiPhoto_1} alt=""/></div>
                        <div className="other swiper-slide"><img src={window.ASSETS.aiPhoto_2} alt=""/></div>
                    </div>
                )}
                <div style={{opacity: AniShow === true ? 1 : 0}} ref={swiper1} className="swiper-container">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_1} alt=""/></div>
                        <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_2} alt=""/></div>
                        <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_3} alt=""/></div>
                        <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_4} alt=""/></div>
                        <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_5} alt=""/></div>
                        <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_6} alt=""/></div>
                        <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_7} alt=""/></div>
                        <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_8} alt=""/></div>
                    </div>
                </div>
            </div>
            <div className="m-imgs">
                <div className="swiper-wrapper">
                    <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_1} alt=""/></div>
                    <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_2} alt=""/></div>
                    <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_3} alt=""/></div>
                    <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_4} alt=""/></div>
                    <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_5} alt=""/></div>
                    <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_6} alt=""/></div>
                    <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_7} alt=""/></div>
                    <div className="swiper-slide"><img src={window.ASSETS.aiPhoto_8} alt=""/></div>
                </div>
            </div>
        </div>
    )
}

export default AiPhoto