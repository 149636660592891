import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

function imgMode() {
    gsap.registerPlugin(ScrollTrigger);
    // 图片模式文字出来
    gsap.fromTo(".imgmode .text .aniText", {
        y: 200, opacity: 0
    }, {
        y: 0, opacity: 1, duration: 1, scrollTrigger: {
            trigger: ".imgmode .text .aniText", start: "top bottom", toggleActions: "play none none none"
        }
    });
    // 平板图片模式动画出来
    let tl = gsap.timeline({
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgmode .ani",
            start: "center+=200 center+=200",
            end: "bottom+=100 bottom",
            toggleActions: "play none reverse none",
        }
    })
    tl.fromTo(".imgmode .ani .tablet", {
        rotateX: 45,
    }, {
        rotateX: 0, duration: 1
    }, 0)
        .to(".imgmode .text .aniText", {
            y: -100, duration: 1,
        }, 0)
        .fromTo(".imgmode .flower_left", {
            transform: 'translate(-720px, 120%)', opacity: 1,
        }, {
            y: 200, duration: 1,
        }, 0)
        .fromTo(".imgmode .flower_right", {
            transform: 'transform: translate(190px, 40%)', opacity: 1,
        }, {
            y: -50, duration: 1,
        }, 0);
    // gsap.fromTo(".imgmode .ani", {
    //     y: 400, opacity: 0
    // }, {
    //     y: 0, opacity: 1, duration: 1, scrollTrigger: {
    //         trigger: ".imgmode .ani",
    //         start: "top bottom",
    //         toggleActions: "play none none none"
    //     }, onComplete: () => {
    //
    //     }
    // })
//     图片散开
    gsap.from(".imgMode_1", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        duration: 2,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_1",
            start: "top-=400 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_2", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        duration: 1.5,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_2",
            start: "top-=600 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_3", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        duration: 1,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_3",
            start: "top-=300 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_4", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_4",
            start: "top+=10 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_5", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_5",
            start: "top-=540 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_6", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_6",
            start: "top-=490 bottom",
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_7", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_7",
            start: "top bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_8", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_8",
            start: "top+=20 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_9", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_9",
            start: "top+=100 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_10", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_10",
            start: "top bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_11", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_11",
            start: "top+=70 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_12", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_12",
            start: "top+=50 bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
    gsap.from(".imgMode_13", {
        left: "50%",
        top: "50%",
        x: "-50%",
        y: "-50%",
        opacity: 0,
        scale: 0,
        scrollTrigger: {
            scrub: 1,
            trigger: ".imgMode_13",
            start: "top bottom",
            end: 'top center',
            toggleActions: "play none reverse none",
        },
    })
}

export default imgMode