import './style.scss'
import './animation.scss'
import {useRef} from "react";
import ImgDiffuse from "./ImgDiffuse";


function ImgMode({current, scrollPosition, deltaY}) {
    const imgmode = useRef(null)
    const aniTitle = useRef(null)
    const smalltitle = useRef(null)
    const aniIntro = useRef(null)
    const tablet = useRef(null)
    const text = useRef(null)
    const flower_left = useRef(null)
    const flower_right = useRef(null)
    const input = useRef(null)
    const ani = useRef(null)
    const typing = useRef(null)
    return (<div ref={imgmode} className={`imgmode`}>
        <div ref={text} className="text">
            <p ref={smalltitle} className="aniText smalltitle">
                Artificial Intelligence Image
            </p>
            <h1 ref={aniTitle} className="aniText">
                图片生成
            </h1>
            <p ref={aniIntro} className="intro aniText">
                像聊天一样生成图片，让创作变得更简单<br/>一次Promt多种风格，无限激发灵感，让想象变具象
            </p>
        </div>
        <div className="ani" ref={ani}>
            {/*<ImgDiffuse></ImgDiffuse>*/}
            <div ref={input} className="input" style={{backgroundImage: `url(${window.ASSETS.imgMode_input})`}}>
                <div className="input-left">
                    <div>
                        <span className="icon iconfont bm-suiji_normal"></span>
                    </div>
                    <div>
                        <span className="icon iconfont bm-i2i_normal"></span>
                    </div>
                </div>
                <div className="input-center">
                    <p ref={typing}>面部细节特写，飘逸的头发,精致的眼部，精致的发饰，棕色眼睛，深邃的眼睛,精致的眼部</p>
                </div>
                <div className="input-right">
                    <div>
                        <span className="icon iconfont bm-delete_20"></span>
                    </div>
                    <div>
                        <img src={window.ASSETS.imgMode_ink} alt=""/>
                        <p>4</p>
                    </div>
                </div>
            </div>
            <div className="tabletWrap">
                <img ref={tablet} className="tablet" src={window.ASSETS.imgMode_tablet} alt=""/>
            </div>
            <img ref={flower_left} className="flower_left" src={window.ASSETS.imgMode_leftFlower} alt=""/>
            <img ref={flower_right} className="flower_right" src={window.ASSETS.imgMode_rightFlower} alt=""/>
        </div>
    </div>)
}

export default ImgMode