export const lyricsMap = new Map([
    ['00:01.50', {en: "We had a vision", zh: "我们有一个梦想"}],
    ['00:04.94', {en: "to friends with a passion", zh: "一群伙伴怀揣着热情"}],
    ['00:08.58', {en: "Dreaming of something more than the daily grind", zh: "梦想超越平凡"}],
    ['00:10.98', {en: "We took a leap of faith", zh: "我们怀揣着信念"}],
    ['00:14.02', {en: "No time for hesitation", zh: "毫不犹豫"}],
    ['00:16.72', {en: "Creating our own destiny", zh: "创造属于我们的命运"}],
    ['00:20.13', {en: "Leaving it all behind", zh: "克服一切的困难"}],
    ['00:25.53', {en: "Late nights and coffee", zh: "深夜和咖啡相伴"}],
    ['00:27.33', {en: "Brainstorming our ideas", zh: "头脑风暴我们的想法"}],
    ['00:29.89', {en: "Building castles in the air", zh: "一点点筑起梦想的城堡"}],
    ['00:32.31', {en: "Making plans to soar", zh: "制定计划勇敢起飞"}],
    ['00:35.37', {en: "We faced the challenges", zh: "我们面对挑战"}],
    ['00:37.97', {en: "Conquered our fears", zh: "战胜恐惧"}],
    ['00:40.15', {en: "United by our dreams", zh: "梦想让我们团结"}],
    ['00:42.65', {en: "Together we'll open doors", zh: "一起开启未来的大门"}],
    ['00:45.05', {en: "We're chasing our dreams", zh: "我们追逐梦想"}],
    ['00:48.10', {en: "Reaching for the stars", zh: "追逐星辰"}],
    ['00:51.05', {en: "Hand in hand", zh: "我们携手并进"}],
    ['00:53.78', {en: "We'll go far", zh: "我们将走得更远"}],
    ['00:55.33', {en: "Entrepreneur's dream", zh: "创业者的梦想"}],
    ['00:58.30', {en: "It's in our veins", zh: "流淌在我们的血液中"}],
    ['01:00.74', {en: "With each step we take", zh: "每一步的前行"}],
    ['01:03.01', {en: "Nothing can detain us", zh: "没有什么能阻挡我们"}],
    ['01:30.18', {en: "We're chasing our dreams", zh: "我们追逐梦想"}],
    ['01:33.29', {en: "Reaching for the stars", zh: "追逐星辰"}],
    ['01:36.17', {en: "Hand in hand", zh: "我们携手并进"}],
    ['01:38.14', {en: "We'll go far", zh: "我们将走得更远"}],
    ['01:40.58', {en: "Entrepreneur's dream", zh: "创业者的梦想"}],
    ['01:43.42', {en: "It's in our veins", zh: "流淌在我们的血液中"}],
    ['01:46.28', {en: "With each step we take", zh: "每一步的前行"}],
    ['01:49.38', {en: "Nothing can detain us", zh: "没有什么能阻挡我们"}]
]);
export const timeStringToMilliseconds = (timeString) => {
    const parts = timeString.split(':');
    let minutes = parseInt(parts[0], 10);
    const secondsAndMillis = parts[1].split('.');
    let seconds = parseInt(secondsAndMillis[0], 10);
    let milliseconds = parseInt(secondsAndMillis[1] || '00', 10);

    // 计算总毫秒数
    let totalMilliseconds = (minutes * 60 * 1000) + (seconds * 1000) + milliseconds;

    // 处理进位
    while (milliseconds >= 1000) {
        milliseconds -= 1000;
        seconds++;
    }

    while (seconds >= 60) {
        seconds -= 60;
        minutes++;
    }

    // 返回总毫秒数
    return totalMilliseconds;
}