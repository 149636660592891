import "./style.scss"

function NavBar() {
    return (
        <div className="navbar">
            <div className="nav-mask">

            </div>
            <div className="boxs">
                <img className="logo" alt="" src={window.ASSETS.home_logo}/>
                <div className="apikey" onClick={() => window.open(window.CONFIG.ApiDoc)}>API文档</div>
            </div>
            <button className="move-ani" onClick={() => window.open(window.CONFIG.console)}>
                立即开始
            </button>
        </div>
    )
}

export default NavBar