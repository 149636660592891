import './style.scss'
import {useState, useEffect} from 'react'


function Footer({mode}) {
    const [brandLeft, setBrandLeft] = useState(78)
    useEffect(() => {
        const intervalId = setInterval(() => {
            setBrandLeft(prevBrandLeft => {
                if (Math.floor(prevBrandLeft) === (mode === 'pc' ? -2448 : -740)) {
                    prevBrandLeft = (mode === 'pc' ? 205 : 65)
                }
                return prevBrandLeft - 0.2
            });
        }, 1);
        // 清除定时器以防止内存泄漏
        return () => clearInterval(intervalId);
    }, []);
    return (
        <div className="footer">
            <div className="m-brand">
                {window.ASSETS.brands.map((item,index)=>{
                    return (
                        <img key={index} src={item} alt=""/>
                    )
                })}
            </div>
            <div className="footer-wrap">
                <div className="brand">
                    <div style={{transform: `translateX(${brandLeft}px)`}}>
                        <img src={window.ASSETS.footer_brand} alt=""/>
                        <img src={window.ASSETS.footer_brand} alt=""/>
                    </div>
                </div>
                <img className="astronaut" src={window.ASSETS.footer_astronaut} alt=""/>
                <div className="contact">
                    <h1 className="aniText">
                        联系我们
                    </h1>
                    <p className="aniText">
                        邮箱：bimoai@126.com
                    </p>
                </div>
                <div className="info">
                    {/*<div className="hr"></div>*/}
                    <p>上海万笔千墨科技有限公司</p>
                    <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer">
                        网站备案号:沪ICP备2024049267号
                    </a>
                    <a target="_blank" href="https://beian.cac.gov.cn/#/index" rel="noreferrer">
                        算法备案号:网信算备310115230253701240015号
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer