import './style.scss'
import {useState, useRef, useEffect} from "react";
import {timeStringToMilliseconds, lyricsMap} from './handle'

function AiMusic() {
    const [timeoutIds, setTimeoutIds] = useState(0)
    const video1 = useRef(null)
    const video2 = useRef(null)
    const video3 = useRef(null)
    const ulElement = document.querySelector('ul');
    const changeMusicMode = () => {
        setPlayMusic(true)
        audio.current.play();
        playBtn.current.style.animation = "none"
        setPlayMode(!playMode)
        video2.current.play()
        video1.current.style.zIndex = 0
    }
    const handleAudioEnd = () => {
        for (let i = timeoutIds - 30; i <= timeoutIds; i++) {
            clearTimeout(i);
        }
        console.log("播放完毕")
        ulElement.style.display = 'none'
        audio.current.play();
        setCurrent(0)
    }
    useEffect(() => {
        const videoElement = video2.current;
        const handleVideoEnded = () => {
            video2.current.style.zIndex = 0
            video3.current.play()
        };
        videoElement.addEventListener('ended', handleVideoEnded);

        return () => {
            videoElement.removeEventListener('ended', handleVideoEnded);
        };
    }, []);
    const audio = useRef(null);
    const playBtn = useRef(null)
    const [playMode, setPlayMode] = useState(false)
    useEffect(() => {
        if (playMode === true) {
            Promise.all(promises()).then(res => {
                console.log("所有歌词播放完毕")
            })
            if (audio.current.paused || audio.current.ended) {
                audio.current.play().then(() => {
                    // Video is playing
                }).catch(error => {
                    console.error('Playback failed:', error);
                });
            }
            if (video2.current.paused || video2.current.ended) {
                video2.current.play().then(() => {
                    // Video is playing
                }).catch(error => {
                    console.error('Playback failed:', error);
                });
            }
            if (video3.current.paused || video3.current.ended) {
                video3.current.play().then(() => {
                    // Video is playing
                }).catch(error => {
                    console.error('Playback failed:', error);
                });
            }
        } else {
            console.log(timeoutIds, "暂停")
            for (let i = timeoutIds - 30; i <= timeoutIds; i++) {
                clearTimeout(i);
            }
            audio.current.play().then(() => {
                audio.current.pause()
            }).catch(error => {
                console.error('Playback failed:', error);
            });
            video3.current.play().then(() => {
                video3.current.pause()
            }).catch(error => {
                console.error('Playback failed:', error);
            });
            video2.current.play().then(() => {
                video2.current.pause()
            }).catch(error => {
                console.error('Playback failed:', error);
            });
        }
    }, [playMode])
    const [current, setCurrent] = useState(0)
    const [playMusic, setPlayMusic] = useState(false)
    const promises = () => {
        let time = 0
        if (current !== 0) {
            time = timeStringToMilliseconds(Array.from(lyricsMap.entries())[current - 1][0])
        }
        return Array.from(lyricsMap.entries()).slice(current).map((item, index) => {
            return new Promise((resolve) => {
                let timeoutId = setTimeout(() => {
                    setCurrent(prevCurrent => prevCurrent + 1);
                }, timeStringToMilliseconds(item[0]) - time)
                setTimeoutIds(timeoutId)
            })
        })
    }
    useEffect(() => {
        if (current === 0 && playMusic) {
            ulElement.style.display = 'inherit'
            Promise.all(promises()).then(res => {
                console.log("所有歌词播放完毕")
            })
        }
        if (ulElement !== null) {
            ulElement.style.transform = `translateY(-${(54 * current)}px)`;
        }
        console.log(current)
    }, [current])
    return (
        <div className="aimusic">
            <div className="text">
                <p className="aniText">
                    Artifcial Intelligence Music
                </p>
                <h1 className="aniText">
                    AI音乐
                </h1>
                <p className="aniText">
                   <span>
                        AI音乐通过先进的算法和机器学习模型，分析大量音乐数据，识别出其中的规律和特征，从而生成AI音乐作品。探索AI音乐的创作自由与无限可能，人人都能成为音乐创作者
                   </span>
                </p>
            </div>
            <div className="video">
                <div className="mask"></div>
                <div className="shadow">

                </div>
                <video playsInline ref={video1} onClick={(e) => changeMusicMode()} className="music" loop autoPlay
                       muted>
                    <source src={window.ASSETS.aiMusic_1} type="video/mp4"/>
                </video>
                <video playsInline ref={video2} className="music" muted>
                    <source src={window.ASSETS.aiMusic_2} type="video/mp4"/>
                </video>
                <video playsInline ref={video3} className="music" loop muted>
                    <source src={window.ASSETS.aiMusic_3} type="video/mp4"/>
                </video>
                <div className="shadow">

                </div>
                <img onClick={(e) => changeMusicMode()} ref={playBtn}
                     src={playMode === true ? window.ASSETS.aiMusic_play : window.ASSETS.aiMusic_pause} alt=""/>
                {/*歌词*/}
                {playMusic && (
                    <div className="lyrics">
                        <ul>
                            {
                                Array.from(lyricsMap.entries()).map((item, index) => {
                                    return (
                                        <li key={index} className={current === index ? 'active' : ''}>
                                            <p>{item[1].en}</p>
                                            <p>{item[1].zh}</p>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                )}
            </div>
            <audio onEnded={handleAudioEnd} ref={audio} src={window.ASSETS.aiMusic_Mp3}
                   style={{display: "none"}}></audio>
        </div>
    )
}

export default AiMusic