import './style.scss'

function ImgDiffuse() {
    const imgPaths = Array.from({length: 13}, (_, index) => window.ASSETS[`imgMode_${String(index + 1).padStart(2, '0')}`]);
    return (
        <div className="img-diffuse">
            <h1>Inspire Your Creation</h1>
            {imgPaths.map((src, index) => (
                <img className={`imgMode_${index + 1}`} key={index} src={src} alt={`imgMode_${index + 1}`}/>
            ))}
        </div>
    )
}

export default ImgDiffuse;