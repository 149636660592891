import './style.scss'
import {useEffect, useState} from "react";

const member = [...window.ASSETS.teamMember, ...window.ASSETS.teamMember, ...window.ASSETS.teamMember]

function Mobile() {
    const [speed, setSpeed] = useState(0.2)
    const [photoLeft, setPhotoLeft] = useState(0)
    useEffect(() => {
        document.querySelector(".m-team .photo").style.transition = "none"
        const intervalId = setInterval(() => {
            setPhotoLeft(prev => {
                if (Math.floor(prev) <= -1232) {
                    prev = 0
                }
                return prev - speed
            });
        }, 1);
        return () => clearInterval(intervalId);
    }, []);
    return (
        <div className="m-team">
            <div className="title">
                <h1>
                    我们的团队
                </h1>
                <p>
                    汇聚了一批经验丰富的软件工程师、数据科学家，AI专家和创意设计师
                </p>
            </div>
            <div className="photowrap">
                <div className="leftMask"></div>
                <div className="photo" style={{transform: `translateX(${photoLeft}px)`}}>
                    {member.map((item, index) => {
                        return (
                            <div key={index} className="member">
                                <img src={item.img} alt=""/>
                                <p>
                                    {item.job}
                                </p>
                            </div>
                        )
                    })}
                </div>
                <div className="rightMask"></div>
            </div>
        </div>
    )
}

export default Mobile