import {createBrowserRouter} from 'react-router-dom'
import App from '../App';
import Index from '../components/Index'
function isMobileDevice() {
    return [{
        index: true,
        element: <Index/>
    }, {
        path: '/index/:name',
        element: <Index/>
    }, {
        path: '/index',
        element: <Index/>
    }];
}

const route = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: isMobileDevice()
    }
])
export default route