import './style.scss'


function DoWhat() {
    const onMouseEnter = (e) => {
        e.target.querySelector("div").style.transform = "translateY(0)"
        e.target.style.animation = "forwards 0.5s backgroundSize"
    }
    const onMouseLeave = (e) => {
        e.target.querySelector("div").style.transform = "translateY(80px)"
        e.target.style.animation = "forwards 0.5s backgroundSize_b"
    }
    return (
        <div className="dowhat">
            <div className="title aniText">
                <img className="left" src={window.ASSETS.doWhat_leftLine} alt=""/>
                <p>我们做什么</p>
                <img className="right" src={window.ASSETS.doWhat_rightLine} alt=""/>
            </div>
            <div className="m-title">
                <p>我们做什么</p>
                <div></div>
            </div>
            <div className="able">
                <div onMouseOut={(e) => onMouseLeave(e)} onMouseOver={(e) => onMouseEnter(e)} className="left"
                     style={{backgroundImage: `url(${window.ASSETS.doWhat_1})`}}>
                    <div>
                        <h1>多模态的<br/>模型应用能力</h1>
                        <p>
                            跨越文字、音乐与视觉的智能交互，开启全新AI应用场景
                        </p>
                    </div>
                </div>
                <div onMouseLeave={(e) => onMouseLeave(e)} onMouseEnter={(e) => onMouseEnter(e)} className="center"
                     style={{backgroundImage: `url(${window.ASSETS.doWhat_2})`}}>
                    <div>
                        <h1>提供<br/>一站式解决方案</h1>
                        <p>
                            前后端、算力、云服务一站式服务，满足您的多样化需求
                        </p>
                    </div>
                </div>
                <div onMouseLeave={(e) => onMouseLeave(e)} onMouseEnter={(e) => onMouseEnter(e)} className="right"
                     style={{backgroundImage: `url(${window.ASSETS.doWhat_3})`}}>
                    <div>
                        <h1>自研的<br/>AIGC视觉模型</h1>
                        <p>
                            创新驱动，实现渲染效果的叠加，打造个性化视觉新体验
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DoWhat